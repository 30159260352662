.modal-move-task-wlb {
  max-width: 672px;
  border-radius: 5px;
}
.slider-rail-wlb-move-task {
  height: 9px;
  background-color: #eff2f4;
  border-radius: 5px;
}
.slider-track-wlb-move-task {
  height: 9px;
  color: #039be5;
  border-radius: 5px;
}
.slider-thumb-wlb-move-task {
  height: 24px;
  width: 24px;
  margin-top: -8px;
}

.slider-root-wlb-move-task {
  max-width: 420px;
}
.text-field-root-wlb-move-task {
  max-width: 175px;
}
.text-field-root-wlb-move-task div input {
  text-align: right;
}
